<template>
  <panel-action ref="panelActionRef" :panelTitle="customLayOutTitle">
    <!-- 表单 -->
    <div class="form-box ml-scrollbar">
      <ml-form
        :model="formData"
        :rules="formRules"
        labelWidth="100px"
        ref="formRef"
        @confirm="formSubmit"
        showConfirm
        labelPosition="top"
        showBtn
        bold
      >
        <div class="form-warpper">
          <el-form-item label="设备型号：" prop="model" v-if="!isActionEdit">
            <ml-input
              placeholder="请输入设备型号"
              v-model="formData.model"
            ></ml-input>
          </el-form-item>

          <el-form-item label="设备示意图：" prop="coverType">
            <el-radio-group v-model="formData.coverType" @change="changeType">
              <el-radio
                :label="item.value"
                size="large"
                v-for="(item, index) in deviceSketchMapType"
                :key="'item' + index"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <!-- 按需展示 -->
          <!-- 选择其他型号图片  展示 -->
          <div class="none-img" v-if="!isNoneImg">
            <el-form-item label="设备型号：" prop="retrieval" v-if="isOtherImg">
              <ml-input
                placeholder="请输入设备型号"
                v-model="retrieval"
                ref="modelInputRef"
                @input="onInputDeviceName"
                @blur="onBlurDeviceName"
                @focus="onFocusDeviceName"
              >
                <!-- 下拉选择 -->
                <template #warpper-down-area>
                  <!-- <div class="filter-device-list" v-if="existModelList.length"> -->
                  <div class="filter-device-list hide-scrollbar" v-if="true">
                    <div
                      class="device-item"
                      v-for="item in existModelList"
                      :key="'on' + item.id"
                      @click.stop="changeDeviceItem(item)"
                    >
                      <div class="left oneLineOver">
                        <div class="logo">
                          <ml-image
                            size="mini"
                            :preview="false"
                            :src="item.coverImage"
                          ></ml-image>
                        </div>
                        <p class="name oneLineOver">
                          {{ item.model }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="empty-text" v-else>未筛选到设备型号</div>
                </template>
              </ml-input>
              <div class="tips">
                <div class="tips show-line-tips">
                  温馨提示：请输入GBMS设备型号
                </div>
              </div>
            </el-form-item>

            <!-- 选择新图片上传 展示 -->
            <el-form-item label="" prop="coverImage" v-if="isUpdateImg">
              <div class="upload-img">
                <image-multifunctional
                  v-model="imageFile"
                  :maxCount="1"
                  sizeMode="rect"
                  uploadAreaWidth="150px"
                  uploadAreaHeight="auto"
                  uploadAreaMinHeight="150px"
                  folder="DEVICE_MODEL"
                ></image-multifunctional>
                <div class="tips show-line-tips">
                  温馨提示：仅支持png格式的图片
                </div>
              </div>
            </el-form-item>

            <!-- 其他 / 默认 展示 -->
            <el-form-item
              label=""
              prop="image"
              v-if="(isOtherImg && formData.coverImage) || isDefaultImg"
            >
              <ml-image
                :width="isDefaultImg ? '155px' : '220px'"
                :height="isDefaultImg ? 'auto' : 'auto'"
                :src="formData.coverImage"
                emptyType="custom"
                errorIconSize="70"
                :isPreviewBeforeUrl="!isDefaultImg"
              ></ml-image>
            </el-form-item>
            <!-- 预览按钮 -->
            <el-form-item label="" prop="image" v-if="!previewBtn">
              <ml-button @click="previewFn()">预览手机端效果</ml-button>
            </el-form-item>
          </div>
        </div>
      </ml-form>
    </div>

    <!-- 预览 -->
    <div class="preview-box">
      <!-- 弹窗 -->
      <tool-dialog
        class="fixed-area"
        width="500px"
        ref="previewDialogRef"
        :showBtn="false"
        :showDialogClose="false"
        title="预览"
      >
        <!-- 顶部标签 -->
        <div class="head"></div>
        <!-- 内容区域 -->
        <div class="contain ml-scrollbar">
          <div class="bg-img">
            <div class="show-img">
              <ml-image
                :src="formData.coverImage"
                emptyType="custom"
                width="auto"
                height="240px"
                customErrIconWidth="128px"
                customErrIconHeight="128px"
                fit="contain"
                :isPreviewBeforeUrl="!isDefaultImg"
              ></ml-image>
            </div>
          </div>
        </div>
      </tool-dialog>
    </div>
  </panel-action>
</template>

<script setup>
import {
  onMounted,
  reactive,
  computed,
  ref,
  nextTick,
  watch,
  onUnmounted,
} from "vue";
import { usePageModule } from "@/utils/mixin";
import { deviceSketchMapOpts } from "@/utils/options";

const { proxy, routeParams, mainStore } = usePageModule();

/* 选型数据 */
// 设备示意图类型
const deviceSketchMapType = deviceSketchMapOpts();

// 设备检索
const retrieval = ref();

// 设备ID
const deviceId = computed(() => routeParams.id);
// 页面操作类型 add||edit
const actionType = computed(() => routeParams.actionType);
// 页面编辑
const isActionEdit = computed(() => actionType.value == "edit");
// 选项类型 3选择其他型号图片 ||2选择新图片上传 ||1使用默认图片 ||0暂无图片
const isOtherImg = computed(() => formData.coverType == 3);
const isUpdateImg = computed(() => formData.coverType == 2);
const isDefaultImg = computed(() => formData.coverType == 1);
const isNoneImg = computed(() => formData.coverType == 0);
// 设备默认图片 本地
const deviceDefaultImg = require("@/assets/images/default-device.png");

// 顶部标题
const customLayOutTitle = computed(() => {
  const titleType = {
    add: "添加",
    edit: "修改",
  };
  const type = titleType[actionType.value];

  return `${type}设备型号`;
});

// 预览按钮展示
const previewBtn = computed(() => {
  const showBtn = proxy.$isEmpty(formData.coverImage);
  return showBtn;
});

/* 表单操作 */
// ref
const formRef = ref();
// 设备型号  ref
const modelInputRef = ref();
// 预览弹窗 ref
const previewDialogRef = ref();
// 默认数据
const formDefaultData = () => ({
  id: "",
  coverDeviceId: "",
  coverType: 0,
  coverImage: "",
  model: "",
});
// 表单数据
const formData = reactive(formDefaultData());
// 表单验证
const formRules = {
  model: [
    {
      required: true,
      message: "请输入设备型号",
    },
  ],
  coverType: [
    {
      required: true,
      message: "请选择设备示意图",
    },
  ],
  coverImage: [
    {
      required: true,
      message: "请上传图片",
    },
  ],
};

/* 上传图片数据 */
const imageFile = ref([]);
watch(
  () => imageFile.value,
  (nVal) => {
    formData.coverImage = nVal.map((d) => d.relUrl).join(",");
  },
  {
    deep: true,
  }
);

const changeType = () => {
  const { coverDeviceModel, coverImage, coverType } = deviceCopyDetail;
  if (isActionEdit) {
    // 重置
    imageFile.value = [];
    formData.coverImage = "";
    retrieval.value = "";

    // 其他
    if (isOtherImg.value && coverType == formData.coverType) {
      // console.log(coverImage);
      setTimeout(() => {
        retrieval.value = coverDeviceModel;
        formData.coverImage = coverImage;
      });
    }

    // 上传
    if (isUpdateImg.value && coverType == formData.coverType) {
      imageFile.value = [
        {
          relUrl: coverImage,
        },
      ];
    }
  } else {
    formData.coverImage = "";
    retrieval.value = "";
  }
  // 默认
  if (isDefaultImg.value) {
    setTimeout(() => {
      formData.coverImage = deviceDefaultImg;
    });
  }
};

/* 设备型号检索 */
// 已存在的设备型号列表
const existModelList = ref([]);

// 监听设备型号输入
const onInputDeviceName = (res) => {
  // 添加
  nextTick(() => {
    getDeviceCoverImagePageList();
  });
};

// 监听获取焦点
const onFocusDeviceName = async () => {
  // 检索设备型号
  await getDeviceCoverImagePageList();
};

// 失去焦点
const onBlurDeviceName = () => {
  setTimeout(() => {
    modelInputRef.value.closeWarpperArea();
  }, 300);
};

// 选择设备型号
const changeDeviceItem = async (item) => {
  const { id, coverImage, model } = item;

  // 设置回显
  // 赋值
  retrieval.value = model;
  formData.coverDeviceId = id;
  imageFile.value = coverImage ? [{ relUrl: coverImage }] : [];

  setTimeout(() => {
    modelInputRef.value.closeWarpperArea();
    // 重置表单验证
    formRef.value.clearValidate();
  }, 300);
};

/* 预览弹窗 */
//打开 预览弹窗
// const priviewHeadImg = require("../../assets/images/preview-head.png");
// const priviewFootImg = require("@/assets/images/preview-foot.png");
const previewFn = () => {
  // 打开预览弹框
  previewDialogRef.value.openDialog();
};

// 提交
const formSubmit = async (close) => {
  const testRes = await proxy.$testForm(close, formRef.value);

  if (!testRes) {
    return;
  }

  const isEdit = isActionEdit.value;
  const fetchName = isEdit ? "fetchModifyDevicer" : "fetchAddDevicer";
  const disptchTips = isEdit ? "修改成功" : "添加成功";

  let result = formData;

  // 如果是暂无或者是默认图片
  if (isNoneImg.value || isDefaultImg.value) {
    result = {
      ...result,
      coverDeviceId: null,
      coverImage: null,
    };
  }

  // 编辑
  if (isActionEdit.value) {
    // console.log(deviceDetail);
    // console.log(formData);

    // 编辑删除这个属性
    delete formData["model"];
    // 对比详情和新输入的值，过滤出有变动的项保存
    result = proxy.$getDataUpdateAfter(deviceDetail, formData);
  }
  // console.log(result);
  const resultLength = Object.keys(result).length;
  if (!resultLength) {
    // 没有任何修改
    proxy.$successMsg(disptchTips);
    proxy.$delayBack();
    return;
  } else {
    // 有修改 填补接口字段
    const { model, coverType } = formData;
    result.id = deviceId.value;
    result.coverType = coverType;
    if (model) {
      result.model = model;
    }
  }

  try {
    // console.log(result);
    await proxy.$storeDispatch(fetchName, result);
    proxy.$delayBack(() => {
      proxy.$successMsg(disptchTips);
    });
    close();
  } catch (error) {
    close();
  }
};

/* 查询操作 */
// 数据
const deviceDetail = reactive(formDefaultData());
// 备份
let deviceCopyDetail = {};
// 查询设备型号详情
const getDeviceDetails = async () => {
  const id = deviceId.value;
  try {
    const res = await proxy.$storeDispatch("fetchGetDeviceDetails", { id });
    const result = res.result;
    proxy.$updateParams(formData, result);
    proxy.$updateParams(deviceDetail, result);
    deviceCopyDetail = result;
    // 赋值关联设备型号
    retrieval.value = result.coverDeviceModel;
    // 赋值上传图片
    if (isUpdateImg.value && result.coverImage) {
      imageFile.value = [{ relUrl: result.coverImage }];
    }

    // 默认图片
    if (isDefaultImg.value) {
      formData.coverImage = deviceDefaultImg;
    }

    // console.log(formData);
  } catch (error) {}
};

// 获取已存在的设备型号
const getDeviceCoverImagePageList = async () => {
  try {
    const model = retrieval.value;
    if (proxy.$isEmpty(model)) {
      existModelList.value = [];

      return;
    }
    const res = await proxy.$storeDispatch("fetchGetDeviceCoverImagePageList", {
      model,
    });
    const result = res.result || [];
    existModelList.value = result;

    if (result.length) {
      modelInputRef.value.openWarpperArea();
    }
  } catch (error) {}
};

// 初始
const init = () => {
  getDeviceDetails();
};

onMounted(async () => {
  // 赋值标题
  mainStore.customLayOutTitle = customLayOutTitle.value;

  // 修改
  if (deviceId.value) {
    init();
  }
});

onUnmounted(() => {
  mainStore.customLayOutTitle = "";
});
</script>

<style lang="scss" scoped>
.form-box {
  width: 60%;
  margin: auto;
  overflow-y: auto;
  height: 60vh;
  ::v-deep(.el-form) {
    display: grid;
    grid-template-rows: 1fr auto;

    .role-auths-box {
      width: 100%;
      height: 428px;
      overflow-y: auto;
      .web-auth,
      .applet-auth {
        .title {
          font-weight: bold;
          padding-bottom: 15px;
        }
      }
    }

    .reset-auth {
      color: var(--el-color-primary);
      font-size: 14px;
      cursor: pointer;
    }
  }

  .upload-img {
    display: flex;
    flex-direction: column;
  }
  .tips {
    color: #f59a23;
  }

  .none-img {
    .filter-device-list {
      overflow-y: auto;
      .device-item {
        display: grid;
        grid-template-columns: 1fr auto;
        cursor: pointer;
        margin-bottom: 10px;
        padding-bottom: 6px;
        border-bottom: 1px solid #9e9e9e21;
        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }

        .left {
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          overflow: hidden;

          .name {
            margin-left: 5px;
          }
        }
        .right {
          margin-left: 10px;
          &.is-auth {
            color: var(--el-color-primary);
          }
          &.not-auth {
            color: var(--el-color-warning);
          }
        }
      }
    }
  }
}

// 预览弹窗样式
.preview-box {
  .el-dialog__body {
    padding: 20px 20px 10px 20px !important;
  }
  .head {
    position: sticky;
    top: 0;
    left: 0;
    width: 375px;
    height: 80px;
    background-image: url("../../assets/images/preview-head.png");
    background-repeat: no-repeat;
    background-position: center -12px;
    background-size: cover;
  }
  .contain {
    overflow-y: auto;
    height: 600px;
    .bg-img {
      display: flex;
      justify-content: center;
      padding-top: 5px;
      margin-top: 10px;
      box-sizing: border-box;
      height: 800px;
      background-image: url("../../assets/images/preview-foot.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      .custom-image.normal {
        // width: 200px !important;
        // height: 280px !important;
      }
    }
  }
}
</style>
<style lang="scss">
// 弹窗样式
.fixed-area {
}
</style>
